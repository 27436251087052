import { CardType, EventConfig, UserEventStates } from "@max/common";
import { Timestamp } from "firebase/firestore";

const demoCheckInCard: CardType = {
  visible: {
    states: [UserEventStates.draft],
  },
  tab: "Regístrate",
  headline:
    "¿Quieres tener la oportunidad de ganar algo de merchandising firmado?",
  body: "Regístrese e ingrese para tener la oportunidad de ganar mercancía firmada para Milky Chance",
  cta: "register",
  ctalabel: "Regístrate",
};

const demoVoteCard: CardType = {
  tab: "Fan Favorite",
  headline: "Vote for our Encore",
  cta: "register",
  ctalabel: "Vote",
  songs: [
    { id: "fFXwYxvL", title: "Rerun", album: "Honey Revenge" },
    { id: "pXPmVyAr", title: "Distracted", album: "Honey Revenge" },
    { id: "wNKjrtaS", title: "Miss Me", album: "Honey Revenge" },
  ],
};

const demoCheckInCompleteCard: CardType = {
  visible: {
    states: [UserEventStates.draft],
    hide: true,
  },
  tab: "Check In",
  headline: "Thank You!",
  body: "You can save now my recent release",
  cta: "social",
  ctalabel: "Save",
  image: "https://placekitten.com/200/200",
};

const demoInfoCard: CardType = {
  headline: "Info Goes Here",
  image: "https://placekitten.com/200/200",
  body: "Some other cool info",
  cta: "https://google.com",
  ctalabel: "Go go!",
};

const demoPaymentCard: CardType = {
  headline: "Donar",
  body: "La donación de valores apreciados, como acciones o fondos mutuos, es una manera inteligente y fácil de apoyar a los Jóvenes Artistas de América.",
  cta: "payment",
  ctalabel: "Donar!",
};

const demoMerchCard: CardType = {
  tab: "Merch",
  merch: [
    {
      name: "T-Shirt",
      photo: "https://placekitten.com/200/200",
      url: "https://google.com",
    },
    {
      name: "VIP Shirt",
      photo: "https://placekitten.com/200/240",
      url: "https://google.com",
    },
    {
      name: "Long Name Sweater",
      photo: "https://placekitten.com/200/200",
      url: "https://google.com",
    },
    {
      name: "Fur Gloves",
      photo: "https://placekitten.com/200/200",
      url: "https://google.com",
    },
    {
      name: "LongNameHat",
      photo: "https://placekitten.com/200/200",
      url: "https://google.com",
    },
  ],
};

export const demoConfigEs: EventConfig = {
  type: 2,
  header: {
    artistName: "Ty Dolla Sign",
    image:
      "https://storage.googleapis.com/set-live-stage.appspot.com/artist-images/ty-dolla-sign-album.jpeg",
    date: Timestamp.now(),
    venue: "The Masquerade",
    location: "Atlanta, GA",
  },
  register: {
    title: "Regístrate ahora",
    subtitle: "Complete el formulario rápido para votar",
    terms: `Acepto compartir la información anterior con <span style="font-weight: 800">Home Free</span> y Set.Live. Ingresar su información significa que podemos agregarlo a <span style="font-weight: 800">Home Free</span> listas de marketing por correo electrónico y texto
    <a style="color: #006ACC" onclick="(function(e){
      const event = new Event('setfan-show-terms', {bubbles: true});
      e.target.dispatchEvent(event);
      e.stopPropagation();
      return false;
    })(arguments[0]);return false;">Política de privacidad</a>`,
    customCheckbox: "mi special termz",
    ctalabel: "Vote",
  },
  social: {
    title: "You can now save my album on these platforms",
    subtitle: "rly u can",
    spotify: "https://",
    apple: "https://",
    amazon: "https://",
    deezer: "https://",
    youtube: "https://",
  },
  cards: [
    demoCheckInCard,
    demoCheckInCompleteCard,
    // demoVoteCard,
    demoPaymentCard,
    demoMerchCard,
  ],
};
