import {
  createContext,
  ProviderProps,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getAuth,
  signInAnonymously,
  onAuthStateChanged,
  User,
} from "firebase/auth";
let isRequestingLogin = false;

interface IContext {
  user?: User;
}

export const UserContext = createContext<IContext>({});

export const UserProvider = (
  props: JSX.IntrinsicAttributes & Omit<ProviderProps<IContext>, "value">,
) => {
  const [user, setUser] = useState<User | null>(null);
  const auth = getAuth();
  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  });

  if (user === null && !isRequestingLogin) {
    isRequestingLogin = true;
    try {
      signInAnonymously(auth).finally(() => {
        isRequestingLogin = false;
      });
    } catch (error) {
      isRequestingLogin = false;
      console.error(error);
    }
  }
  if (!user) {
    return null;
  }
  const value = { user };
  return <UserContext.Provider {...props} value={value} />;
};

export const useAuth = () => useContext(UserContext);
