import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import styled from "styled-components";
import dropdownIcon from "../melodies-source/Svgs/src/Dropdown.svg";

const Container = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 0.8fr 0.8fr 1.4fr;
  gap: 8px;
  grid-auto-flow: row;
`;

const Select = styled.select<{ $isPlaceholder?: boolean }>`
  padding: 11px 12px;
  border-radius: 6px;
  color: ${(p) => (p.$isPlaceholder ? "#999" : " var(--max-text)")};
  border: 1px solid #cccccc;
  background-color: #ffffff;
  background-image: url(${dropdownIcon});
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;

  &:focus {
    border: solid 1px var(--max-action);
  }
`;

interface DateOfBirthInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const DateOfBirthInput = ({
  value,
  onChange,
}: DateOfBirthInputProps) => {
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const baseYear = new Date().getFullYear() - 12;

  const handleAll = (month: string, day: string, year: string) => {
    onChange?.(`${month}/${day}/${year}`);
  };

  const handleMonth = (value: string) => {
    setMonth(value);

    if (day && year) {
      handleAll(value, day, year);
    }
  };

  const handleDay = (value: string) => {
    setDay(value);

    if (month && year) {
      handleAll(month, value, year);
    }
  };

  const handleYear = (value: string) => {
    setYear(value);

    if (month && day) {
      handleAll(month, day, value);
    }
  };

  useEffect(() => {
    if (value) {
      const dateTime = DateTime.fromFormat(value, "MM/dd/yyyy");

      if (dateTime.isValid) {
        setMonth(dateTime.toFormat("MM"));
        setDay(dateTime.toFormat("dd"));
        setYear(dateTime.toFormat("yyyy"));
      }
    }
  }, [value]);

  return (
    <Container>
      <Select
        $isPlaceholder={!month}
        value={month}
        onChange={(e) => handleMonth(e.target.value)}
      >
        <option value="" disabled selected>
          MM
        </option>
        {Array.from({ length: 12 }).map((_value, index) => {
          const value = String(index + 1).padStart(2, "0");

          return (
            <option key={index} value={value}>
              {value}
            </option>
          );
        })}
      </Select>
      <Select
        $isPlaceholder={!day}
        value={day}
        onChange={(e) => handleDay(e.target.value)}
      >
        <option value="" disabled selected>
          DD
        </option>
        {Array.from({ length: 31 }).map((_value, index) => {
          const value = String(index + 1).padStart(2, "0");

          return (
            <option key={index} value={value}>
              {value}
            </option>
          );
        })}
      </Select>
      <Select
        $isPlaceholder={!year}
        value={year}
        onChange={(e) => handleYear(e.target.value)}
      >
        <option value="" disabled selected>
          YYYY
        </option>
        {range(baseYear, baseYear - 80, -1).map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Select>
    </Container>
  );
};
