import {
  getFirestore,
  setDoc,
  doc,
  collection,
  DocumentData,
  where,
  query,
} from "firebase/firestore";
import {
  firebaseApp,
  useAuth,
  useEventConfig,
  useLocationContext,
} from "../Components";
import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import {
  createContext,
  ProviderProps,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import LogRocket from "logrocket";

import { UserEventStates } from "@max/common/event";
import { DataState } from "@max/common/user";

export interface DataInterface {
  data: DataState;
  payments: DocumentData[] | undefined;
  total: number;
  setData: React.Dispatch<React.SetStateAction<DataState>>;
  submit: () => Promise<void>;
  setValue: (val: { [key: string]: any }) => void;
}

const defaultState = {
  data: { status: UserEventStates.draft, values: {} },
  payments: [],
  total: 0,
  setData: () => {},
  submit: () => new Promise<void>(() => {}),
  setValue: () => {},
};

const DataContext = createContext<DataInterface>(defaultState);

export const MockDataProvider = (
  props: JSX.IntrinsicAttributes &
    Omit<ProviderProps<DataInterface>, "value"> & { data?: DataState | null },
) => {
  const [data, setData] = useState<DataState>({
    status: UserEventStates.draft,
    values: {},
  });
  const submit = () => Promise.resolve();
  const total = 0;

  const setValue = useCallback((vals: { [key: string]: any }) => {
    setData((prev) => ({
      ...prev,
      values: { ...prev.values, ...vals },
    }));
  }, []);

  const value: DataInterface = {
    data: props.data || data,
    setData,
    submit,
    payments: undefined,
    total,
    setValue,
  };
  return <DataContext.Provider {...props} value={value} />;
};

export const DataProvider = (
  props: JSX.IntrinsicAttributes & Omit<ProviderProps<DataInterface>, "value">,
) => {
  const { user } = useAuth();
  const { eventId } = useEventConfig();
  const { location } = useLocationContext();
  const db = getFirestore(firebaseApp);
  const userDocRef = doc(db, `set_fresh_events/${eventId}/users/${user!.uid}`);
  const [payments] = useCollectionData(
    query(
      collection(db, `set_fresh_events/${eventId}/payments`),
      where("uid", "==", user?.uid),
      where("status", "in", ["submitted", "succeeded"]),
    ),
  );
  const total = (payments || []).reduce((acc, curr) => acc + curr.amount, 0);
  const [userDoc] = useDocument(userDocRef);
  const [data, setData] = useState<DataState>({
    status: UserEventStates.draft,
    values: {},
  });
  useEffect(() => {
    if (userDoc !== undefined) {
      if (userDoc.data()) {
        setData({ ...data, status: userDoc?.data()?.status });
      }
    }
  }, [userDoc]);

  const submit = () => {
    data.values.email = data.values.email?.toLowerCase();
    return setDoc(userDocRef, { ...data, status: UserEventStates.submitted });
  };

  const setValue = useCallback((vals: { [key: string]: any }) => {
    setData((prev) => ({
      ...prev,
      values: { ...prev.values, ...vals },
    }));
  }, []);

  useEffect(() => {
    if (!data.values.coordinates && !!location) {
      setValue({ coordinates: location });
    }
  }, [location]);

  useEffect(() => {
    if (userDoc && user?.uid) {
      let vals = userDoc.data();
      LogRocket.identify(user.uid, {
        name: vals?.firstName + vals?.lastName || "",
        email: vals?.email || "",
        phone: vals?.phone || "",
      });
    }
  }, [userDoc]);

  return (
    <DataContext.Provider
      {...props}
      value={{ data, setData, submit, payments, total, setValue }}
    />
  );
};

export const useDataContext = () => useContext(DataContext);
